import { asText, richText } from '~/utils/html';

export const sliceBuilder = (items = []) => {
  return {
    slice_type: 'anchor_list',
    slice_label: null,
    primary: null,
    items,
  };
};

export default ({ items }, pageData, { $enhancedLinkSerializer }) => {
  const listsByAnchor = {};

  items
    .filter((item) => item.data?.title && item.data?.lexicon)
    .forEach((item) => {
      const title = asText(item.data.title);
      const anchor = title.charAt(0);

      if (!listsByAnchor[anchor]) {
        listsByAnchor[anchor] = { anchor: anchor.toUpperCase(), items: [] };
      }

      const link = $enhancedLinkSerializer({
        ...item,
        link_type: 'Document',
        data: { ...item.data, market: item.data.lexicon.data?.market || null },
      });

      listsByAnchor[anchor].items.push({
        label: title,
        link,
      });
    });

  const lists = Object.keys(listsByAnchor).map((key) => listsByAnchor[key]);
  lists.sort((a, b) => a.anchor.localeCompare(b.anchor));

  return {
    title: richText(pageData.title),
    lists,
  };
};
